@media (min-width: 300px) and (max-width: 600px) {
  .navbar {
    padding-left: 0%;
  }

  body .shortDesc {
    margin-top: 0px;
  }

  body .card-row{
    min-height: unset;
  }

  body .container-promo {
    padding: 30% 0% 10% 6%;
  }

  body .headingPromo {
    padding-left: 0px;
  }

  .logo {
    width: 50%;
  }

  .hero-title {
    font-size: 1.4rem !important;
    margin-bottom: 4%;
  }

  .mobile-center {
    text-align: center;
  }

  body .headingOffer {
    height: 7rem;
    margin-top: 25%;
    font-size: 1.5rem;
  }

  body .loginContainer {
    background-position: 48% 0%;
    padding: 10% 10% 10% 15%;
  }

  body .btnOnLogin {
    width: 100%;
    margin-bottom: 1rem;
  }

  body .hero-profile-title {
    font-size: 1.8rem;
    margin-top: 70%;
    line-height: 2rem;
    word-spacing: 0.5rem;
  }

  body .container-profile-why {
    padding: 4% 0% 0% 5%;
  }

  body .headingAboutUs {
    text-align: center;
  }

  body .descAboutUs {
    font-size: 1rem;
  }

  body .aboutUsImg {
    padding-right: 0px !important;
  }

  body .headingAboutUs {
    font-size: 1rem;
  }

  body .headingNews {
    font-size: 1.5rem;
  }

  body .descJoinUs {
    display: none;
  }

  body .mobile-bottom-margin {
    margin-bottom: 0rem !important;
  }

  body .mobile-top-padding {
    padding-top: 1rem !important;
  }

  body .container-footer {
    text-align: center;
    padding: 0% 0% 0% 5%;
  }

  body .cardNewsPageDetail {
    padding-left: 0px;
  }

  body .mobile-no-margin-padding {
    margin: 0px !important;
    padding: 0px !important;
  }

  body .mobile-no-margin-bottom {
    margin-bottom: 0px !important;
  }

  body .mobile-no-margin {
    margin: 0px !important;
  }

  body .mobile-no-padding {
    padding: 0px !important;
  }

  body .mobile-1-padding-top {
    padding-top: 1rem !important;
  }

  body .arrow-provide {
    text-align: center !important;
  }

  body .mobile-1-padding {
    padding: 1rem !important;
  }

  body .mobile-h-2 {
    height: 10rem;
  }

  body .no-mobile {
    display: none !important;
  }

  body .mobile-icon {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  body .mobile-center {
    text-align: center !important;
  }

  body .arrow-provide-left {
    margin: 1rem !important;
  }

  body .cardTesti {
    margin-left: 2rem;
    margin-top: 1rem;
  }

  body .cardTesti .w100 {
    width: 54%;
  }

  body .mobile-provide-box {
    padding-left: 0px !important;
    margin-left: 3rem !important;
  }

  body .mobile-top-margin {
    margin-top: 1rem;
  }

  body .headingNews {
    padding-left: 0px;
  }

  body .headingWhy {
    padding-left: 2rem !important;
  }

  body .headingProvide {
    padding-left: 0rem !important;
  }

  body .mobile-2 {
    font-size: 1.2rem !important;
  }

  body .mobile-full-100 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  body .container-contact {
    padding: 31% 5% 10% 5% !important;
  }

  body .mobile-no-padding-bottom {
    padding-bottom: 0px !important;
  }

  body .mobile-no-margin-top {
    margin-top: 0px !important;
  }

  body .mobile-1 {
    font-size: 1rem !important;
  }

  body .cardNewsPageBot {
    text-align: center;
  }

  body .containerHowItWorks {
    padding: 40% 11% 11% 11%;
  }

  body .containerNewsDetails {
    padding: 20% 11% 11% 11%;
  }

  body .newsBotTitle {
    margin-top: 1rem;
  }

  body .mobile-center {
    display: flex !important;
    justify-content: center !important;
  }

  body .cardNewsPage {
    padding-left: 2rem !important;
  }

  body .whySectionImg {
    width: 73%;
  }

  body .text-black-mobile-white{
    color: white !important;
  }

  body .containerNews {
    padding: 40% 11% 11% 11%;
  }

  body .newsTopImg {
    height: 10rem;
    width: 91%;
  }

  body .mobile-only {
    display: block !important;
  }

  body .mobile-inline-block {
    display: inline-block !important;
  }

  body .container-news {
    padding: 6% 6% 6% 13%;
  }

  body .heroContainer {
    padding: 20% 5% 6% 5%;
    background-image: none;
  }

  body .headingWhy {
    color: #8e8787;
    padding: 0px;
    text-align: center;
  }

  body .headingProvide {
    color: #8e8787;
    padding: 0px;
    text-align: center;
  }

  body .container-provide {
    background-image: none;
    padding: 4% 0% 10% 0%;
    background-size: cover;
    background-repeat: round;
  }

  body .mobile-margin-left {
    margin-left: 2rem;
  }

  body .headingMember {
    margin-top: 2rem !important;
  }

  body .mobile-width-100 {
    width: 100% !important;
  }

  body .benefitTitle {
    min-height: 3rem;
  }

  body .hero-title {
    line-height: unset;
  }

  body .hero-desc {
    line-height: unset;
  }

  body .benefitDesc {
    margin-top: 0rem;
  }

  body .animated {
    animation-duration: 0s !important;
    opacity: 1 !important;
    animation-name: none !important;
  }

  body .mobile-left-padding {
    padding-left: 1rem;
  }

  body .specialCol {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  body .item {
    max-width: 31rem;
  }

  body .btn-yellow {
    background-color: #f9ee6f;
    color: black;
    font-size: 1rem;
  }

  body .container-why {
    padding: 10% 5% 5% 5%;
  }

  body .container-partners {
    padding-left: 4rem;
  }

  body .rowTesti {
    padding-left: 2rem;
  }

  body .descTeam{
    padding: 0% !important;
  }

  body .teamName {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
  }

  body .teamPos {
    font-size: 0.8rem !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  body .headingContact {
    font-size: 2rem;
  }

  body .hero-title {
    font-family: "Bristone";
    font-size: 2.2rem;
    margin-bottom: 4%;
    margin-top: 15%;
    font-weight: 300;
    color: white;
    text-transform: uppercase;
    word-spacing: 0.5rem;
  }

  body #navbarDropdown {
    padding-top: 7%;
  }

  body .MuiSvgIcon-root {
    width: 0.6em;
    height: 2rem;
  }

  body .arrow .MuiSvgIcon-root {
    width: 1em;
    height: 1em;
  }

  body .bg-transBlue .logo {
    width: 10rem !important;
  }

  body .logo {
    width: 10rem;
  }

  body .btn-login {
    font-size: 0.8rem;
  }

  body .btn-join {
    font-size: 0.8rem;
  }

  body .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 1%;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
  }

  body .hero-desc {
    font-size: 1rem;
    margin-bottom: 0%;
    padding-left: 0%;
    color: white;
    line-height: 25px;
  }

  body .btn-traveli {
    font-size: 2.2rem;
    display: inline-block;
    border-radius: 37px;
    text-align: center;
    font-weight: 600;
    color: black;
    padding-left: 0px;
    width: 220px;
    z-index: 0;
  }

  body .btn-traveli:hover .arrow {
    background-position: left;
    padding: 14px 135px 15px 17pxx;
  }

  body .btn-font {
    font-size: 0.8rem;
    padding-top: 6%;
    padding-left: 0%;
  }

  body .btn-blueTraveli {
    font-size: 1.1rem;
    padding-top: 6%;
    padding-left: 9%;
    transition: 0.2s all;
    text-decoration: underline;
    text-decoration-color: #48afd7;
    color: #48afd7;
  }

  body .btn-traveli:hover .btn-font {
    padding-right: 22px;
    text-decoration: none;
    color: black;
  }

  body .btn-traveli-hover .btn-font {
    padding-right: 22px;
    text-decoration: none;
    color: black;
  }

  body .btn-v3-hero {
    width: 13rem;
  }

  body .btn-v3-hero:hover .arrow {
    background-position: left;
    padding: 8px 100px 8px 10px;
  }

  body .btn-v3-hero:hover .btn-font {
    color: #59a9da;
    padding-right: 50px;
  }

  body .btn-v3-hero .btn-font {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;
    padding-top: 6%;
  }

  body .btn-v3-hero .btn-font-blue {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;
    padding-top: 5%;
  }

  body .btn-v3-hero:hover .btn-blueTraveli {
    color: #59a9da;
    padding-right: 50px;
  }

  body .btn-v3-hero .btn-blueTraveli {
    text-decoration: underline;
    text-decoration-color: #59a9da;
    color: #59a9da;
    padding-top: 5%;
  }

  body .btn-v3-hero .btn-blueTraveli {
    text-decoration: underline;
    text-decoration-color: #59a9da;
    color: #59a9da;
    padding-top: 5%;
  }

  body .btn-v3-hero .MuiSvgIcon-root {
    color: #4ab1d8;
    font-size: 1.5rem !important;
  }

  body .btn-v8-hero {
    width: 13rem;
  }
  
  body .btn-v8-hero-hover {
    width: 13rem;
  }

  body .btn-v8-hero-hover .arrow {
    background-position: left;
    padding: 11px 103px 11px 13px;
  }

  body .btn-v8-hero-hover .btn-font {
    color: #59a9da;
    padding-right: 50px;
    padding-top: 15px;
  }


  body .btn-v8-hero-hover .btn-blueTraveli {
    color: #59a9da;
    padding-right: 50px;
  }


  body .btn-v8-hero:hover .arrow {
    background-position: left;
    padding: 11px 103px 11px 13px;
  }

  body .btn-v8-hero:hover .btn-font {
    color: #59a9da;
    padding-right: 50px;
  }

  body .btn-v8-hero .btn-font {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;
    padding-top: 7.5%;
  }

  body .btn-v8-hero .btn-font-blue {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;
    padding-top: 5%;
  }

  body .btn-v8-hero:hover .btn-blueTraveli {
    color: #59a9da;
    padding-right: 50px;
  }

  body .btn-v8-hero .btn-blueTraveli {
    text-decoration: underline;
    text-decoration-color: #59a9da;
    color: #59a9da;
    padding-top: 5%;
  }

  body .btn-v8-hero .btn-blueTraveli {
    text-decoration: underline;
    text-decoration-color: #59a9da;
    color: #59a9da;
    padding-top: 5%;
  }

  body .btn-v8-hero .MuiSvgIcon-root {
    color: #4ab1d8;
    font-size: 1.5rem !important;
  }

  body .btn-v8-hero .arrow {
    background-color: #f2e968;
    border-radius: 30px;
    padding: 11px;
    transition: 0.2s ease-out;
    position: absolute;
    font-size: 12pt;
  }

  body .arrow {
    background-color: #f2e968;
    border-radius: 30px;
    padding: 8px;
    transition: 0.2s ease-out;
    position: absolute;
    font-size: 12pt;
  }

  body .headingNews {
    font-size: 1.5rem;
  }

  body .headingWhy {
    font-size: 1.5rem;
  }

  body .headingProvide {
    font-size: 1.5rem;
  }

  body .descWhy {
    font-size: 0.8rem;
    text-align: justify;
  }

  body .headWhy {
    font-size: 1.2rem;
  }

  body .card-style {
    padding: 1rem 2rem !important;
  }

  body .headingWhy {
    font-size: 1.5rem;
  }

  body .headingProvide {
    font-size: 1.5rem;
  }

  body .headingPromo {
    font-size: 1.5rem;
  }

  body .menu-section {
    font-size: 1.2rem;
  }

  body .menu-heading {
    font-size: 1.2rem;
  }

  body .menu-heading-provide {
    font-size: 1.2rem;
  }

  body .menu-desc {
    font-size: 1rem;
  }

  body .headingMember {
    line-height: 36px;
    font-size: 1.6rem;
  }

  body .bold2 {
    font-size: 1rem;
  }

  body .btn-v7-hero:hover .arrow {
    background-position: left;
    padding: 8px 120px 8px 10px;
  }

  body .btn-v7-hero:hover .btn-font {
    color: #59a9da;
    padding-right: 50px;
  }

  body .btn-v7-hero .btn-font {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;
    padding-top: 6%;
    padding-left: 0;
  }

  body .btn-v7-hero .btn-font-blue {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;
    padding-top: 5%;
  }

  body .headingOffer {
    font-size: 1.5rem;
    font-weight: 300;
  }

  body .btn-v7-hero:hover .btn-blueTraveli {
    color: #59a9da;
    padding-right: 50px;
  }

  body .btn-v7-hero .btn-blueTraveli {
    text-decoration: underline;
    text-decoration-color: #59a9da;
    color: #59a9da;
    padding-top: 5%;
  }

  body .btn-v7-hero .btn-blueTraveli {
    text-decoration: underline;
    text-decoration-color: #59a9da;
    color: #59a9da;
    padding-top: 5%;
  }

  body .btn-v7-hero .MuiSvgIcon-root {
    color: #4ab1d8;
    font-size: 1.5rem !important;
  }

  body .responsiveImg {
    width: 100%;
  }

  body .right0 {
    padding-right: 0px !important;
  }

  body .benefitTitle {
    font-size: 1.5rem;
  }

  body .pointMembership {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: "Bristone";
  }

  body .benefitDesc {
    font-size: 1rem;
    margin-top: 0px;
    line-height: 21pt;
  }

  body .item {
    max-width: 46rem;
    max-height: 20rem;
  }

  body .headingBenefit {
    font-size: 1.8rem;
  }

  body .members-dropdown p {
    font-size: 0.7rem;
  }

  body .member-list-dropdown p {
    font-size: 0.7rem;
  }

  body .responsiveImg2 {
    width: 70%;
  }

  body .headingAboutUs {
    font-size: 1.6rem;
    line-height: 26pt;
  }

  body .newsHeadingHome {
    font-size: 1.5rem;
  }

  body .newsDescHome {
    font-size: 0.8rem;
  }

  body .browseAll {
    background-color: #f9ee6f;
    height: 2.5rem;
    width: 9rem;
    font-size: 1rem;
    font-weight: 400;
  }

  body .headingPartners {
    font-size: 1.2rem;
  }

  body .fb-13 {
    font-size: 0.9rem;
  }

  body .oneRem {
    font-size: 0.8rem;
  }

  body .oneHalfRem {
    font-size: 1rem !important;
  }

  body .promoModalHead {
    font-size: 1.5rem;
  }

  body .btnYellowPromo {
    width: 12rem;
    height: 2rem;
    font-size: 0.8rem;
  }

  body .modal-content {
    height: 16rem;
  }

  body .hero-profile-title {
    font-size: 2rem;
    font-weight: 300;
    line-height: 3rem;
    word-spacing: 0.5rem;
  }

  body .descAboutUs {
    font-size: 1rem;
  }

  body .number-visitor{
    font-size: 2rem;
  }

  body .details-visitor{
    font-size: 0.6rem;
  }

  body .tag-visitor{
    font-size: 0.7rem;
  }

  body .prinsip {
    font-size: 1.5rem;
  }

  body .headingVM {
    font-size: 1.3rem;
    letter-spacing: 1px;
  }

  body .descVM {
    font-size: 1.1rem !important;
  }

  body .headingTeam {
    font-size: 1.7rem;
  }

  body .descTeam {
    font-size: 1rem;
  }

  body .teamName {
    font-size: 1.2rem;
  }

  body .teamPos {
    font-size: 1rem;
  }

  body .specialCol {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  body .text-black-mobile-white{
    color: black
  }

  body .bg-transBlue .nav-link{
    color: white !important
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  body .logo {
    width: 6rem;
  }

  body .bg-transBlue .logo {
    width: 6rem;
  }

  body .promoModalHead {
    font-size: 1.5rem;
  }

  body .btnYellowPromo {
    width: 12rem;
    height: 2rem;
    font-size: 0.8rem;
  }

  body .modal-content {
    height: 16rem;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1800px) {
  body .hero-profile-title {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }

  body .headingAboutUs {
    font-size: 1.5rem;
  }
}

.text-black {
  color: black !important;
}

.mobile-only {
  display: none !important;
}

.headWhy {
  font-size: 2rem;
  font-family: 'roboto';
}

.descWhy {
  font-size: 1rem;
  text-align: center !important;
}

.descJoinUs {
  font-size: 1rem;
  text-align: center !important;
  min-height: 7rem;
  color: white;
}

.MuiSvgIcon-root {
  width: 1rem;
}

.headingOffer {
  font-family: "Bristone";
  font-size: 2rem;
  font-weight: 300;
  color: #48afd7;
  text-transform: uppercase;
  line-height: 3rem;
  word-spacing: 0.5rem;
}

.headingNewsDetails {
  font-family: "Bristone";
  font-size: 1rem;
  font-weight: 300;
  color: #48afd7;
  word-spacing: 0.5rem;
}

.btnOnLogin {
  font-size: 0.9rem;
  background-color: rgb(255 255 255 / 0.7);
  padding: 5% 20%;
  border-radius: 5px;
  font-family: "Bristone";
  font-weight: 400;
  border: none;
  color: rgb(89 169 218 / 0.6);
  transition: 0.5s all;
}

.btnOnLogin:hover {
  background-color: rgb(255 255 255 / 0.9);
}

.borderTop {
  border-top: 2px solid white;
}

.loginContainer {
  background: #48afd7;
  background-image: url("../images/bgJoinUs.png");
  padding: 10%;
  background-repeat: no-repeat;
  background-size: cover;
}

.followUsFont{
  font-size: 14pt;
  font-weight: 600;
  color: #4fb9dd;
  margin-bottom: 5px;
}

.heroContainer {
  background: #00add9;
  background-image: url("../images/home_Hero.jpg");
  padding: 3% 0% 6% 6%;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroProfileContainer {
  background: #48afd7;
  background-image: url("../images/bgProfile.png");
  padding: 4% 0% 4% 7%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.9);
  height: 55rem;
}

.mobile-inline-block {
  display: none !important;
}

.hero-profile-title {
  font-family: "Bristone";
  font-size: 3.3rem;
  margin-bottom: 4%;
  margin-top: 33%;
  font-weight: 400;
  color: #f6eb52;
  text-transform: uppercase;
  line-height: 4rem;
  word-spacing: 0.5rem;
}

.specialCol {
  -ms-flex: 0 0 58.333333% !important;
  flex: 0 0 58.333333% !important;
  max-width: 58.333333% !important;
}

iframe{
  width: 24vw;
  height: 38vh;
}

.no-border{
  border: none;
}

.hero-title {
  font-family: "Bristone";
  font-size: 2.4rem;
  margin-bottom: 4%;
  margin-top: 15%;
  font-weight: 300;
  color: white;
  text-transform: uppercase;
  line-height: 2.5rem;
  word-spacing: 0.5rem;
}

.hero-subtitle {
  font-size: 2.5rem;
  margin-bottom: 1%;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  line-height: 3rem;
}

.hero-desc {
  font-size: 1.5rem;
  margin-bottom: 0%;
  padding-left: 0%;
  color: white;
  line-height: 22pt;
}

.imageSlider {
  width: 127vw;
  left: 0;
  position: absolute;
}

.sliderItem {
  height: 16vw;
  margin: 0% 5% 0% 0%;
}

.chooseUs-title {
  font-size: 3rem !important;
  margin-bottom: 4%;
  font-weight: 600;
  line-height: 108%;
  color: #2f7996;
  color: black;
}

.chooseUs-subtitle {
  font-size: 1.3rem !important;
  margin-bottom: 4%;
  padding-left: 0%;
  margin-top: 8%;
  font-weight: 300;
  width: 86%;
  color: black;
}

.needs-header {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  font-size: 3.5rem;
  color: #2f7996;
}

.headingNews {
  font-size: 2.5rem;
  font-weight: 400;
  padding-left: 50px;
  color: #48afd7;
  font-family: "Bristone";
}

.headingWhyHome {
  font-size: 2rem;
  font-weight: 400;
  padding-left: 50px;
  color: #48afd7;
  font-family: "Bristone";
}

.headingHowItWorks{
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 3%;
  color: #48afd7;
  font-family: "Bristone";
}

.headingProvide {
  font-size: 2.5rem;
  font-weight: 400;
  padding-left: 50px;
  color: white;
  font-family: "Bristone";
}

.needs-subheader {
  font-size: 1.3rem;
}

.heroSulutions {
  padding: 6% 6% 0% 6%;
}

.heroSolutionPage {
  padding: 6%;
  background: linear-gradient(to top right, #48afd7, #f9ef6f);
}

.container-needs {
  background-color: #ffffff;
}

.container-joinUs {
  background-color: white;
  padding: 10% 6% 6% 6%;
}

.container-news {
  padding: 6% 13%;
}

.pt-8 {
  padding-top: 8%;
}

.pt-9 {
  padding-top: 4.5rem !important;
}

.newsImageOther {
  width: 100%;
  height: 8rem;
}

.newsImageTop {
  width: 100%;
  height: 11rem;
}

.subHeadingTestimonials {
  font-weight: 600;
  font-size: 2.5rem;
}

.teamName {
  font-size: 0.8rem !important;
  font-weight: 600;
  color: black;
  margin-bottom: 0.4rem !important;
  font-family: "Bristone";
}

.teamPos {
  color: #46add8;
  font-weight: 600;
  font-size: 0.7rem !important;
}

.quote {
  border-radius: 15px;
  width: 19%;
}

.cardTesti {
  background-color: rgb(147 200 221 / 0.6);
  border-radius: 15px;
  padding: 3%;
  margin-left: 1rem;
}

.container-howItWorks {
  background-color: #ffffff;
  background-image: url("../images/howItWorks.png");
  background-size: cover;
  margin-left: 5rem !important;
  background-repeat: no-repeat;
  min-height: 220vh;
  padding: 10% 6% 0% 6%;
}

.container-partners {
  background-color: #ffffff;
  /* background-image: url("../images/partners.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10% 15% 10% 15%;
}

.container-testimonials {
  background-color: #ffffff;
  background-image: url("../images/bgTesti.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5%;
  padding-bottom: 14%;
}

.pLR-6 {
  padding: 6% 6% 0% 6%;
}

.spaces {
  padding-bottom: 28%;
}

.joinUsTitle {
  font-weight: 500;
  font-size: 1.8rem;
  color: #48afd7;
  margin-bottom: 10%;
}

.testimonialsTitle {
  font-weight: 500;
  font-size: 1.8rem;
  color: black;
  margin-top: 10%;
  margin-bottom: 0px;
}

.testimonialsSubTitle {
  font-weight: 700;
  font-size: 3.5rem;
  color: #48afd7;
  margin-bottom: 10%;
}

.titleSection {
  font-weight: 600;
  font-size: 1.8rem;
  color: #48afd7;
  margin-bottom: 0px;
}

.section1 {
  margin-top: 4%;
  margin-left: 0%;
}

.section2 {
  margin-top: 21%;
  margin-left: 3%;
}

.section3 {
  margin-top: 25%;
  margin-left: 5%;
}

.section4 {
  margin-top: 25%;
  margin-left: 9%;
}

.section5 {
  margin-top: 15%;
  /* margin-left: 20%; */
}

.ml-7 {
  margin-left: 8%;
}

.inputFooter {
  margin: 2% 0%;
  height: 57px;
  border: none;
  border-radius: 6px;
  opacity: 0.8;
}

.abs {
  position: absolute;
  bottom: 0;
}

.headingTitle {
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  color: white;
}

.description {
  font-weight: 400;
  color: white;
}

.space-top1 {
  margin-top: 34%;
}

.space-top2 {
  margin-top: 85%;
  margin-left: 20%;
}

.gear {
  height: 60%;
  margin-left: 10%;
  margin-top: 10%;
  border-radius: 16px;
  -webkit-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
}

.gear2 {
  height: 70%;
  margin-left: 10%;
  margin-top: 10%;
  border-radius: 16px;
  -webkit-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
}

.subTitleSection {
  font-weight: 500;
  font-size: 1.1rem;
}

.caraKerja {
  font-weight: 600;
  font-size: 1.8rem;
  color: black;
  margin-bottom: 0px;
}

#headAcc {
  flex-basis: 100% !important;
  font-size: 17pt !important;
  font-weight: bolder !important;
  color: #337893;
}

.MuiPaper-root:hover #headAcc {
  color: #918381;
}

.container-supp-dist {
  padding: 10% 6% 6% 6%;
}

.containerChooseUs {
  padding: 10% 6% 10% 6%;
  background-color: #48afd7;
}

.supp-dist-img {
  width: 61px;
}

.packageContainer {
  margin-bottom: 52vh;
  background-color: transparent;
}

.container-benefits {
  background-image: url("../images/home_benefits.png");
  padding: 7% 0% 10% 0%;
  background-size: cover;
}

.headingMembership {
  font-family: "Bristone";
  font-size: 1.2rem;
  letter-spacing: 3px;
  font-weight: 600;
}

.headingAboutUs {
  font-weight: 600 !important;
  margin-top: 5%;
}

.headingVM {
  font-family: "Bristone";
  font-size: 1.8rem;
  letter-spacing: 3px;
  font-weight: 400;
  color: #f8f092;
  text-transform: uppercase;
}

.mb-05{
  margin-bottom: 0.5rem;
}

.descVM {
  font-size: 1.1rem !important;
  color: white;
  font-weight: 600;
}

.mt-6 {
  margin-top: 6rem;
}

.descAboutUs {
  font-size: 1.3rem;
  font-weight: 400;
  color: #525050;
  margin-top: 2rem;
  text-align: justify;
}

.aboutUsImg {
  border-radius: 20px;
}

.stepImg {
  width: 100%;
  height: auto;
}

.container-why {
  padding: 4% 0% 5% 0%;
}

.container-profile-why {
  padding: 7% 0% 6% 0%;
  background-image: url("../images/bgAbout.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.container-contact {
  padding: 10% 0% 0% 0%;
  background-image: url("../images/bgAbout.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.promoImage {
  width: 100%;
  text-align: center;
  border-radius: 20px;
  height: 20rem;
  -webkit-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
}
.card-visitor{
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  border-radius: 5px;
  border: 1px #e5e9ee solid;
  background-color: white;
  padding: 5% 0% 0% 0% ;
}

.details-container{
  padding-right: 0px !important;
  padding-left: 0px !important;
  text-align: center;
  overflow: hidden;
}

.details-visitor{
  margin-bottom: 0px !important;
  font-size: 0.7rem;
  background: rgb(203,203,203);
  background: linear-gradient(0deg, rgba(203,203,203,1) 0%, rgba(242,241,241,1) 46%, rgba(255,255,255,1) 100%); 
}

.number-visitor{
  color: #394153;
  font-size: 2.5rem;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  height: 2.9rem;
  font-weight: 300;
}

.tag-visitor{
  color: #b9c0ce;
  margin-top: 0px !important;
  padding-top: 0px !important;
  font-weight: 400;
  font-size: 0.7rem;
}

.container-promo {
  padding: 10% 6% 10% 6%;
}

.titlePromo {
  font-weight: 600;
}

.w-55 {
  width: 55%;
}

.container-profile-team {
  margin: 10% 15% 7% 15%;
  background-image: url("../images/bgTeam.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.container-vision-mission {
  padding: 4% 6% 4% 6%;
  margin-top: 3%;
  background-image: url("../images/bgVisiMisi.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.container-provide {
  background-image: url("../images/bgProvide.png");
  padding: 4% 0% 10% 0%;
  background-size: cover;
  background-repeat: round;
}

/* Set the size of the div element that contains the map */
.mapouter {
  height: 55rem;
  width: 100%;
}

.gmap_canvas {
  height: 100%;
  width: 100%;
}

#gmap_canvas {
  height: 90%;
  width: 110%;
  border-radius: 20px;
}

.contactSection {
  padding-top: 10%;
}

.oneRem {
  font-size: 1rem;
}

.oneHalfRem {
  font-size: 1.5rem;
  font-family: 'roboto';
  padding-top: 3px !important;
  margin: 0px !important;
}

.newsSecHeading {
  font-size: 1.2rem;
  font-weight: 400;
}

.f-15 {
  font-size: 15pt;
}

.f-14 {
  font-size: 14;
}

.f-13 {
  font-size: 13pt;
}

.fb-13 {
  font-size: 13pt;
  font-weight: 500;
}

.bold {
  font-size: 0.8rem;
  font-weight: 600;
}

.text-black-mobile-white{
  color: black !important;
}

.pointMembership {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: "Bristone";
}

.membership-section {
  margin-top: 18%;
}

.thumbnail-provide {
  max-width: 90%;
  height: auto;
}

.icon-provide {
  width: 4rem;
  height: auto;
}

.radius-1 {
  border-radius: 5px;
}

.container-solution {
  padding: 15% 0% 12% 0%;
  background-color: white;
}

.container-footer {
  background-color: #48afd7;
  background-image: url("../images/bgFooter.png");
  padding: 2% 6% 1% 6%;
  background-size: cover;
}

.socialIcon {
  width: 10%;
  margin: 0.5%;
}

.socialIconBlue {
  width: 13%;
  margin: 0% 1%;
}

.pr-7 {
  padding-right: 5rem;
}

.w100 {
  width: 100%;
}

.w70 {
  width: 70%;
}

.w50 {
  width: 50%;
}

.bg-blue2 {
  background-color: #b0dcec;
  border-radius: 5px;
  margin-top: 1%;
}

.MuiIconButton-label .MuiSvgIcon-root {
  font-size: 2.5rem !important;
}

.MuiAccordion-root {
  position: initial !important;
}

.position-initial {
  position: initial;
}

.card-style {
  border-radius: 30px;
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  padding-bottom: 2rem !important;
  background-color: white;
}

.btnArrow {
  padding: 0px;
  border-radius: 48px;
}

.btnArrow .MuiSvgIcon-root {
  color: #48afd7;
}

button {
  outline: none !important;
  box-shadow: none !important;
}

/* equal card height */
.cardSlider {
  height: 100%;
  width: 100%;
}
.row-equal > div[class*="col-"] {
  display: flex;
  flex: 1 0 auto;
}

.row-equal .card {
  width: 100%;
}

/* ensure equal card height inside carousel */
.carousel-inner > .row-equal.active,
.carousel-inner > .row-equal.next,
.carousel-inner > .row-equal.prev {
  display: flex;
}

/* prevent flicker during transition */
.carousel-inner > .row-equal.active.left,
.carousel-inner > .row-equal.active.right {
  opacity: 0.5;
  display: flex;
}

/* control image height */
.card-img-top-250 {
  max-height: 250px;
  overflow: hidden;
}

.cardBenefits {
  border-radius: 20px;
  -webkit-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
}

.img-fluid {
  border-radius: 20px;
}

.text-black {
  color: black;
}

.carousel-item-next {
  opacity: 0;
}

.arrow {
  background-color: #f2e968;
  border-radius: 30px;
  padding: 14px 15px 15px 17px;
  transition: 0.2s ease-out;
  position: absolute;
  font-size: 12pt;
}

.arrowTrans {
  background-color: #c6dbe4;
  border-radius: 22px;
  padding: 14px 15px 15px 17px;
  transition: 0.2s ease-out;
  position: absolute;
  font-size: 12pt;
}

.arrowTrans .MuiSvgIcon-root {
  color: white;
  font-size: 1.5rem !important;
}

.row {
  max-width: 100%;
}

.padding-7 {
  padding-top: 7%;
}

.radio {
  width: 74%;
}

.btn-membership {
  font-size: 1rem;
  border-radius: 15px;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 5%;
  text-transform: uppercase;
}

.membership-active {
  font-weight: 700;
  background-color: #d0e6ef;
}

.subMenu {
  height: 30px;
}

.sub-menu-button {
  text-transform: uppercase;
  color: #b2e6f2;
  font-size: 0.7rem;
  padding: 2% 7% !important;
  margin: 0px 10px;
  font-family: 'Bristone';
  letter-spacing: 2px;
  border: 1px solid white;
  border-radius: 8px;
  color: white;
  width: 90%;
}


.sub-menu-button-active {
  background-color: #f6eb52;
  color: #00b0d8;
  padding: 2% 7% !important;
  font-family: 'Bristone';
}

.sub-menu-button-active:hover {
  color: white;
}

.blue-soft {
  color: #b2e6f2;
}

.sub-menu {
  font-size: 11pt;
  padding-top: 2%;
  cursor: pointer;
}

.sub-menu-active {
  font-weight: 600;
}

.padding5{
  padding: 0% 3%;
}


.btn-traveli-hover {
  font-size: 1.2rem;
  display: inline-block;
  border-radius: 37px;
  text-align: center;
  font-weight: 500;
  color: black;
  padding-left: 0px;
  width: 220px;
  z-index: 0;
}

.btn-traveli-hover .arrow{
  background-position: left;
  padding: 14px 135px 15px 17pxx;
}

.btn-traveli {
  font-size: 1.2rem;
  display: inline-block;
  border-radius: 37px;
  text-align: center;
  font-weight: 500;
  color: black;
  padding-left: 0px;
  width: 220px;
  z-index: 0;
}

.btn-traveli:hover .arrow {
  background-position: left;
  padding: 14px 135px 15px 17pxx;
}

.btn-font {
  font-size: 1.1rem;
  padding-top: 6%;
  padding-left: 9%;
  transition: 0.2s all;
  text-decoration: underline;
  text-decoration-color: #48afd7;
  color: #48afd7;
}

.btn-blueTraveli {
  font-size: 1.1rem;
  padding-top: 6%;
  padding-left: 9%;
  transition: 0.2s all;
  text-decoration: underline;
  text-decoration-color: #48afd7;
  color: #48afd7;
}

.btn-traveli:hover .btn-font {
  padding-right: 22px;
  text-decoration: none;
  color: black;
}

.btn-v2 {
  width: 280px;
}

.btn-v2 .btn-font {
  padding-top: 5%;
}

.btn-v2:hover .arrow {
  background-position: left;
  padding: 14px 190px 15px 17px;
}

.btn-v3 {
  width: 230px;
}

.btn-v3:hover .arrow {
  background-position: left;
  padding: 14px 160px 15px 17px;
}

.btn-v3-hero {
  width: 253px;
}

.btn-v3-hero:hover .arrow {
  background-position: left;
  padding: 14px 150px 15px 17px;
}

.btn-v3-hero:hover .btn-font {
  color: #59a9da;
  padding-right: 50px;
}

.btn-v3-hero .btn-font {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  padding-top: 5%;
}

.btn-v3-hero .btn-font-blue {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  padding-top: 5%;
}

.btn-v3-hero:hover .btn-blueTraveli {
  color: #59a9da;
  padding-right: 50px;
}

.btn-v3-hero .btn-blueTraveli {
  text-decoration: underline;
  text-decoration-color: #59a9da;
  color: #59a9da;
  padding-top: 5%;
}

.btn-v3-hero .btn-blueTraveli {
  text-decoration: underline;
  text-decoration-color: #59a9da;
  color: #59a9da;
  padding-top: 5%;
}

.btn-v3-hero .MuiSvgIcon-root {
  color: #4ab1d8;
  font-size: 1.5rem !important;
}

.btn-v4 {
  width: 240px;
}

.btn-v4:hover .arrow {
  background-position: left;
  padding: 14px 160px 15px 17px;
}

.btn-v4 .MuiSvgIcon-root {
  color: #4ab1d8;
  font-size: 1.5rem !important;
}

.btn-v5 {
  width: 230px;
}

.btn-v5 .btn-font {
  color: black;
}

.btn-v5:hover .btn-font {
  color: #59a9da;
}

.btn-v5:hover .arrow {
  background-position: left;
  padding: 14px 150px 15px 17px;
}

.btn-v6 {
  width: 240px;
}

.btn-v6 .btn-font {
  color: white;
}

.btn-v6:hover .btn-font {
  color: black;
}

.btn-v6:hover .arrow {
  background-position: left;
  padding: 14px 160px 15px 17px;
}

.btn-v7-hero {
  width: 310px;
}

.btn-v7-hero:hover .arrow {
  background-position: left;
  padding: 14px 180px 15px 17px;
}

.btn-v7-hero:hover .btn-font {
  color: #59a9da;
  padding-right: 50px;
}

.btn-v7-hero .btn-font {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  padding-top: 5%;
  padding-left: 0;
}

.btn-v7-hero .btn-font-blue {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  padding-top: 5%;
}

.btn-v7-hero:hover .btn-blueTraveli {
  color: #59a9da;
  padding-right: 50px;
}

.btn-v7-hero .btn-blueTraveli {
  text-decoration: underline;
  text-decoration-color: #59a9da;
  color: #59a9da;
  padding-top: 5%;
}

.btn-v7-hero .btn-blueTraveli {
  text-decoration: underline;
  text-decoration-color: #59a9da;
  color: #59a9da;
  padding-top: 5%;
}

.btn-v7-hero .MuiSvgIcon-root {
  color: #4ab1d8;
  font-size: 1.5rem !important;
}


.btn-v8-hero-hover {
  width: 253px;
  padding-top: 0px !important;
}

.btn-v8-hero-hover .arrow {
  background-position: left;
  padding: 14px 150px 15px 17px;
}

.btn-v8-hero-hover .btn-font {
  color: #59a9da;
  padding-right: 50px;
  text-decoration: none;
}

.btn-v8-hero-hover .MuiSvgIcon-root {
  height: 0.5em !important;
  width: 0.5em !important;
}



.btn-v8-hero {
  width: 253px;
  padding-top: 0px !important;
}

.btn-v8-hero:hover .arrow {
  background-position: left;
  padding: 14px 150px 15px 17px;
}

.btn-v8-hero:hover .btn-font {
  color: #59a9da;
  padding-right: 50px;
}

.btn-v8-hero .btn-font {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  padding-top: 5%;
}

.btn-v8-hero .btn-font-blue {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
  padding-top: 5%;
}

.btn-v8-hero:hover .btn-blueTraveli {
  color: #59a9da;
  padding-right: 50px;
}

.btn-v8-hero .btn-blueTraveli {
  text-decoration: underline;
  text-decoration-color: #59a9da;
  color: #59a9da;
  padding-top: 5%;
}

.btn-v8-hero .btn-blueTraveli {
  text-decoration: underline;
  text-decoration-color: #59a9da;
  color: #59a9da;
  padding-top: 5%;
}

.btn-v8-hero .MuiSvgIcon-root {
  color: #4ab1d8;
  font-size: 1.5rem !important;
}


.MuiAccordionSummary-root {
  padding: 0px 31px !important;
  height: 72px !important;
}

.blueTraveli {
  color: #49b1d8;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-10 {
  margin-top: 10rem;
}

.menu-section {
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: inherit;
  font-family: "Bristone";
}

.responsiveImg {
  width: 100%;
  height: auto;
}

.clientLogo {
  width: 75%;
  height: auto;
}

.menu-heading {
  font-size: 1.5rem;
  margin-top: 2%;
  letter-spacing: inherit !important;
}

.menu-heading-provide {
  font-size: 1.5rem;
  margin-top: 2%;
  font-family: "roboto";
  letter-spacing: inherit !important;
}

.menu-desc {
  margin-top: 3%;
  font-size: 1.1rem;
  font-weight: 400;
}

.ml-6 {
  margin-left: 4rem !important;
}

.cardNewsPageDetail {
  border: 1px solid #efe7e7;
  border-radius: 25px;
  padding: 2%;
  transition: 0.3s all;
  -webkit-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
}

.cardNewsPage {
  border: 1px solid #efe7e7;
  border-radius: 25px;
  padding: 2%;
  transition: 0.3s all;
  -webkit-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
}

.cardNewsPageBot {
  border: 1px solid #efe7e7;
  border-radius: 10px;
  padding: 2%;
  transition: 0.3s all;
  -webkit-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
}

.cardNews {
  border: 1px solid #efe7e7;
  border-radius: 10px;
  padding: 2%;
  transition: 0.3s all;
}

.cardNews:hover {
  -webkit-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 71px 11px rgba(0, 0, 0, 0.15) !important;
}

.browseAll {
  background-color: #f9ee6f;
  height: 3rem;
  width: 10rem;
  font-size: 1.3rem;
  font-weight: 400;
  border: none;
  border-radius: 10px;
}

.headingPromo {
  font-size: 2rem;
  font-weight: 400;
  padding-left: 50px;
  color: #48afd7;
  font-family: "Bristone";
}

.headingContact {
  font-size: 2.5rem;
  font-weight: 400;
  color: #48afd7;
  font-family: "Bristone";
}

.headingTeam {
  font-size: 2.5rem;
  font-weight: 400;
  color: #48afd7;
  font-family: "Bristone";
}

.descTeam {
  font-size: 1.2rem;
  font-weight: 400;
}

.headingBenefit {
  font-family: "Bristone";
  font-size: 2rem;
  font-weight: 400;
  padding-left: 50px;
  color: white;
}

.headingPartners {
  font-family: "Bristone";
  font-size: 1.5rem;
  color: #48afd7;
  font-weight: 400;
  letter-spacing: 5px;
  /* padding-left: 50px; */
}

.headingTesti {
  font-family: "Bristone";
  font-size: 1.5rem;
  color: #48afd7;
  font-weight: 400;
  letter-spacing: 5px;
  /* padding-left: 50px; */
}

.headingWhy {
  font-size: 2rem;
  color: white;
  font-weight: 400;
  font-family: "Bristone";
  padding-left: 50px;
  word-spacing: 5px;
  line-height: 41px;
}

.floatingWA {
  width: 3.4rem;
  position: fixed;
  right: 2.5rem;
  bottom: 2.5rem;
  z-index: 3;
  transition: 0.3s all;
}

.floatingWA:hover {
  width: 3.9rem;
}

.whySectionImg {
  width: 100%;
}

.headingMember {
  font-family: "Bristone";
  font-size: 2rem;
  color: #46add8;
  font-weight: 400;
  word-spacing: 5px;
  line-height: 47px;
}

.teamImg {
  border-radius: 15px;
  width: 100%;
}

.teamCard {
  border-radius: 30px;
  -moz-box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px -11px rgba(0, 0, 0, 0.39) !important;
  background-color: white;
  width: 74.5%;
  margin-bottom: 1rem;
}

.descTeam {
  padding: 4% 0% 9% 9%;
}

.teamPos {
  color: #46add8;
}

.solutionDesc {
  margin-left: 5%;
  margin-right: 5%;
  font-weight: 300;
  font-size: 1rem;
  color: black;
  margin-top: 3%;
}

.solutionTitle {
  font-weight: 600;
  font-size: 2rem;
  color: black;
  margin-top: 3%;
  margin-left: 5%;
}

.headingSolution {
  font-size: 3.5rem;
  color: #48afd7;
  font-weight: 600;
}

.solutionSubhead {
  font-weight: 600;
  font-size: 1.8rem;
  color: black;
  margin-bottom: 0px;
}

.headingFeature {
  font-size: 3.5rem;
  font-weight: 600;
  color: white;
}

.subheadFeature {
  font-weight: 400;
  color: white;
  font-size: 1.3rem;
  margin-bottom: 0px;
  margin-bottom: 5%;
}

.pl-7 {
  padding-left: 9rem !important;
}

.video {
  margin-top: 7%;
  border-radius: 19px;
}

.needs-second-section {
  margin-top: 31%;
}

.textSlider {
  margin-top: 3%;
  min-height: 57vh;
}

.traveliColor {
  color: #59a9da !important;
}

.card-row{
  min-height: 20rem;
}

.clientLogoHome{
  width: 80%;
}

.benefitTitle {
  font-weight: 600;
  font-size: 1.5rem;
  min-height: 5rem;
  text-align: left;
  font-family: "Bristone";
}

.vertical-align-middle{
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefitDesc {
  font-weight: 400;
  margin-bottom: 0px;
  color: #6a7794;
  font-size: 1rem;
  text-align: left;
  margin-top: 8%;
  line-height: 26pt;
  font-family: "roboto";
}

.bg-yellow2 {
  background-color: #f9ee6f;
  border-radius: 0px 65px 65px 0px;
  height: 13vh;
  width: 29vw;
  border: 1px solid #f9ee6f;
  overflow: hidden;
  transition: 0.2s all;
}

.bg-yellow2:hover {
  background-color: #46add8;
}

.solution {
  -webkit-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px;
  margin-bottom: 5%;
  min-height: 26vh;
  margin-right: 3%;
}

.solution-active {
  background-color: #89c9e0;
  color: white;
}

.solution-card-1 {
  background-size: cover;
  background-image: url("../images/icon1.png");
  transition: all 0.2s;
}

.solution-card-1:hover {
  background-image: url("../images/icon1Active.png");
}

.solution-card-hover-1 {
  background-image: url("../images/icon1Active.png");
}

.solution-card-hover-1 .solution-text {
  color: white;
}

.solution-card-2 {
  background-size: cover;
  background-image: url("../images/icon2.png");
  transition: all 0.2s;
}

.solution-card-2:hover {
  background-image: url("../images/icon2Active.png");
}

.solution-card-hover-2 {
  background-image: url("../images/icon2Active.png");
}

.solution-card-hover-2 .solution-text {
  color: white;
}

.solution-card-3 {
  background-size: cover;
  background-image: url("../images/icon3.png");
  transition: all 0.2s;
}

.solution-card-3:hover {
  background-image: url("../images/icon3Active.png");
}

.solution-card-hover-3 {
  background-image: url("../images/icon3Active.png");
}

.solution-card-hover-3 .solution-text {
  color: white;
}

.solution-card-4 {
  background-size: cover;
  background-image: url("../images/icon4.png");
  transition: all 0.2s;
}

.solution-card-hover-4 {
  background-image: url("../images/icon4Active.png");
}

.solution-card-hover-4 .solution-text {
  color: white;
}

.solution-card-4:hover {
  background-image: url("../images/icon4Active.png");
}

.solution-card-5 {
  background-size: cover;
  background-image: url("../images/icon5.png");
  transition: all 0.2s;
}

.solution-card-5:hover {
  background-image: url("../images/icon5Active.png");
}

.solution-card-hover-5 {
  background-image: url("../images/icon5Active.png");
}

.solution-card-hover-5 .solution-text {
  color: white;
}

.solution-card-6 {
  background-size: cover;
  background-image: url("../images/icon6.png");
  transition: all 0.2s;
}

.solution-card-6:hover {
  background-image: url("../images/icon6Active.png");
}

.solution-card-hover-6 {
  background-image: url("../images/icon6Active.png");
}

.solution-card-hover-6 .solution-text {
  color: white;
}

.solution-main-card {
  -webkit-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px;
  height: 56vh;
  padding-left: 0;
  padding-right: 0;
}

.solution-text {
  position: absolute;
  bottom: 0;
  min-height: 50px;
  font-weight: 700;
  font-size: 1.2rem;
}

.imgSol {
  height: 250px;
  background-size: cover;
  border-radius: 20px 20px 0px 0px;
}

.imgSol-1 {
  background-image: url("../images/solImg1.png");
}

.imgSol-2 {
  background-image: url("../images/solImg2.png");
}

.imgSol-3 {
  background-image: url("../images/solImg3.png");
}

.imgSol-4 {
  background-image: url("../images/solImg4.png");
}

.imgSol-5 {
  background-image: url("../images/solImg5.png");
}

.imgSol-6 {
  background-image: url("../images/solImg6.png");
}

/* START card slider */

.grid-container {
  background: #efefef;
  font-family: "Rubik", sans-serif;
  width: 100%;
}

@supports (display: grid) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;

    grid-template-areas:
      "header header header"
      "title title footer"
      "main main main";
    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr 1fr auto 1fr;
      grid-template-areas:
        "header"
        "title"
        "main"
        "footer";
    }
  }

  .grid-item {
    color: #fff;
    font-size: 1em;
    font-weight: 700;
  }

  .header {
    background-color: darken(skyblue, 60%);
    grid-area: header;
    padding: 1em;
  }

  .title {
    color: #555;
    background-color: lighten(skyblue, 25%);
    grid-area: title;
  }

  .footer {
    background-color: darken(skyblue, 10%);
    grid-area: footer;
    padding: 0.6em;
  }

  .items {
    /* position: relative;
    width: 100vw; */
    /* overflow-x: scroll; */
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.1s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    left: 0;
    height: 83vh;
    position: absolute;
  }

  .cardSliderRoom {
    height: 83vh;
    overflow: hidden;
    position: absolute;
    right: 0 !important;
  }

  .items.active {
    background: rgba(255, 255, 255, 0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  .item {
    display: inline-block;
    min-width: 9rem;
    white-space: normal;
    margin: 2em 1.5em;
    max-width: 51rem;
    background-color: #fbfbfb;
    border-radius: 20px;
    @media screen and (max-width: 500px) {
      min-height: 200px;
      min-width: 200px;
    }
  }

  .itemGanjel {
    display: inline-block;
    min-width: 9rem;
    white-space: normal;
    margin: 2em 1.5em;
    max-width: 70rem;
    background-color: #fbfbfb;
    border-radius: 20px;
    @media screen and (max-width: 500px) {
      min-height: 200px;
      min-width: 200px;
    }
  }

  .move {
    transition: 1s all;
    transform: translateX(-100px);
  }
}

.mt-12 {
  margin-top: 14rem !important;
}

/* END card slider */

.yellow-bg {
  background-color: #f9ee6f;
  padding: 4% 4% 2% 4%;
  border-radius: 20px;
}

.white-bg {
  background-color: white;
  border-radius: 18px;
}

.all-in-one {
  font-size: 3rem;
  font-weight: 600;
  color: #2f7996;
}

.supplier-heading {
  font-size: 2.3rem;
  font-weight: 600;
  color: black;
  line-height: 38px;
}

.supplier-subheading {
  font-size: 14pt;
  color: black;
}

.supp-child {
  font-size: 1.7rem;
  color: #2f7996;
}

.hover-image {
  background-image: url("../images/supp1.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.1s all;
}

.supplier-overview {
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.supplier-overview2 {
  width: 300px;
  height: 300px;
  transition: all 0.5s;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.readMore {
  margin-right: 2%;
  font-weight: 500;
}

.readMoreBot {
  font-size: 0.6rem;
}

.newsTopTitle {
  color: #00add9;
  font-weight: 600;
  font-size: 1.5rem;
}

.newsBotTitle {
  color: #00add9;
  font-weight: 400;
  font-size: 1rem;
  padding-right: 2%;
  line-height: 1.2rem;
}

.shortDesc {
  font-size: 0.7rem;
}

.newsTopImg {
  width: 98%;
  -webkit-box-shadow: 0px 16px 30px 3px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 16px 30px 3px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 16px 30px 3px rgba(0, 0, 0, 0.15) !important;
  border-radius: 25px;
}

.detailNews {
  padding: 1% 7% 10% 7%;
}

.newsDetailImg {
  width: 100%;
  -webkit-box-shadow: 9px 4px 14px 4px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 9px 4px 14px 4px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 9px 4px 14px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 25px 25px 0px 0px;
}

.newsBotImg {
  width: 100%;
  -webkit-box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.15) !important;
  border-radius: 1px;
  height: 9rem;
}

.containerHowItWorks {
  padding: 13% 5% 5% 5%;
}

.container-member{
  background-image: url("../images/members/benefits-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.containerNews {
  background-image: url("../images/bgNews.png");
  padding: 11%;
  background-repeat: no-repeat;
  background-size: cover;
}

.containerNewsDetails {
  background-image: url("../images/bgNews.png");
  padding: 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.dateNews {
  margin-top: 1rem;
  font-size: 0.7rem;
}

.dateNewsBot {
  margin-top: 0.5rem;
  font-size: 0.6rem;
  margin-bottom: 0.5rem;
}

.hover-effect:hover .hover-image {
  background-image: url("../images/supp1Hover.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.hover-effect2:hover .hover-image {
  background-image: url("../images/supp1Hover.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.imageSection1 {
  background-image: url("../images/server.jpeg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0%;
  opacity: 1;
}

.imageSection2 {
  background-image: url("../images/fb.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0%;
  opacity: 1;
}

.imageSection3 {
  background-image: url("../images/benefit1ID.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0%;
  opacity: 1;
}

.imageSection4 {
  background-image: url("../images/benefit2ID.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0%;
  opacity: 1;
}

.imageSection5 {
  background-image: url("../images/contoh.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0%;
  opacity: 1;
}

.imageSection6 {
  background-image: url("../images/supp1Hover.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0%;
  opacity: 1;
}

.radio {
  width: 17px;
  height: 17px;
  margin: 4px;
  border: none;
  border-radius: 25px;
}

.radioEmpty {
  background-image: url("../images/emptyRadio.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
  margin: 4px;
  border: none;
  border-radius: 25px;
}

.radioFilled {
  background-image: url("../images/filledRadio.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
  margin: 4px;
  border: none;
  border-radius: 25px;
}

.parallaxText {
  font-size: 24rem;
  color: #f6fbfd;
  font-weight: 900;
}

.pb-7 {
  padding-bottom: 7%;
}

.sliderHero {
  margin-bottom: -30% !important;
}

.heroSolutionSliderText {
  font-weight: 600;
  font-size: 1.5rem;
  color: #00add9;
}

.sliderText {
  width: 65%;
  background-color: white;
  border-radius: 75px;
  -webkit-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
}

.sliderSolution {
  background-color: white;
  border-radius: 75px;
  -webkit-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.15) !important;
}

.sliderTextActive {
  background-color: #f8f092;
  border-radius: 70px;
}

.mt-3M {
  margin-top: -3%;
}

.heroSliderTitle {
  color: #48afd7;
  font-weight: 500;
  font-size: 1.2rem;
}

.heroSliderSubTitle {
  font-weight: 400;
  font-size: 1.2rem;
}

.carouselJoinUs {
  filter: invert(100%);
  width: 33px;
  height: 33px;
}

.carousel-control-next {
  margin-right: 77%;
}

.carousel-control-prev {
  margin-left: 77%;
}

.carousel-item-next {
  opacity: 1;
}

.foot-title {
  font-size: 3.5rem !important;
  margin-bottom: 4%;
  margin-top: 28%;
  font-weight: 600;
  color: white;
}

.checkBoxFooter {
  content: none;
  height: 40px;
  width: 40px;
  border-radius: 43px;
}

.btnSignUp {
  background-color: #f9ee6f;
  border: none;
  margin-top: 25px;
  width: 100%;
  font-weight: 400;
  font-size: 1.4rem;
  border-radius: 10px;
  padding: 2%;
}

.fontCheckFooter {
  margin-left: 5%;
  font-size: 1.3rem;
  font-weight: 400;
  color: white;
  margin-top: 9px;
}

.pl-55 {
  padding-left: 2.5rem !important;
}

.mt-7 {
  margin-top: 7% !important;
}

.heroSlider {
  height: 66vh;
  width: 53vw;
  background-size: cover;
  background: no-repeat;
  background-image: url("../images/heroSlide1.png");
}

.heroSliderImg1 {
  background-image: url("../images/heroSlide1.png") !important;
}

.heroSliderImg2 {
  background-image: url("../images/heroSlide2.png") !important;
}

.heroSliderImg3 {
  background-image: url("../images/heroSlide3.png") !important;
}

/* Over mobile size */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 62%;
    text-align: center;
  }

  .modal-content {
    color: white;
    width: 82%;
    height: 22rem;
    position: fixed;
    background-color: #48afd7;
    left: 9%;
    bottom: 4%;
    border-radius: 29px;
  }

  iframe {
    width: 20vw;
    height: 28vh;
    border-radius: 15px;
  }
}

.pl-6 {
  padding-left: 7%;
}

.bg-yellow {
  background-color: #f9ee6f;
  border-radius: 24px;
  height: 62vh;
  width: 29vw;
  border: 1px solid #f9ee6f;
  overflow: hidden;
}

.promoModalHead {
  font-size: 2.3rem;
}

.promoModalDesc {
  font-size: 1.1rem;
  color: white;
}

.btnYellowPromo {
  width: 15rem;
  height: 3rem;
  border: none;
  border-radius: 5px;
  background-color: #f7ec1e;
  -webkit-box-shadow: 0px 5px 43px 3px rgba(0, 0, 0, 0.39) !important;
  -moz-box-shadow: 0px 5px 43px 3px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px 3px rgba(0, 0, 0, 0.39) !important;
}

.imagePromo {
  -webkit-box-shadow: 0px 5px 43px 3px rgba(0, 0, 0, 0.39) !important;
  -moz-box-shadow: 0px 5px 43px 3px rgba(0, 0, 0, 0.39) !important;
  box-shadow: 0px 5px 43px 3px rgba(0, 0, 0, 0.39) !important;
  border-radius: 15px;
  width: 70%;
  height: 10rem;
}
