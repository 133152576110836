
@media only screen and (min-width: 1200px) and (max-width: 1500px) {

  body .navbar{
    font-size: 0.8rem;
  }

}


@media (min-width: 300px) and (max-width: 600px) { 
  body .navbar-nav{
    background-color: #4db6d8;
    padding-left: unset  !important;
    padding-right: unset !important ;
    margin-left: unset !important;
    margin-top: unset !important;
  }

  body .navbar-nav .dropdown-menu{
    width: 100%;
    z-index: 1;
    position: absolute;
  }

  body .nav-item{
    text-align: center !important;
    width: 90%;
    margin: 1rem !important;
    height: 2rem;
  }

  body .space-left{
    padding-left: 0px !important;
  }

  body .nav-link{
    margin-left:  0px !important;
  }
}

.navbar{
  padding-left: 4% ;
  font-weight: 500;
  font-size: 1rem;
  color:white;
  background-color: transparent;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  transition: 0.7s all;
  height: 8rem;
}

.navBlue{
  background-color: rgb(70 174 218 / 0.8) !important;
}

.bg-transBlue{
  background-color: rgb(70 174 218 / 0.8) !important;
  height: 5rem;
  -webkit-box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important; 
  -moz-box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important; 
  box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important;
}

.bg-transBlue .nav-link{
  color: white;
}

.bg-transBlue aboutUs{
  color: white !important;
}

.bg-transBlue language{
  color: white !important;
}

.navbarBlue{
  background-color: rgb(70 174 218 / 0.8) !important;
}


.nav-link{
  font-weight: 400;
  padding-left: 0px;
}

.borderLeft{
  border-left: 1px solid #bfbfbfb5;
  padding-left: 30px;
  padding-right: 0px;
}

.logo{
  width: 100%;
  padding-top: 3%;
  transition: 0.7s all;
  padding-right: 0%;
}

.bg-transBlue .logo{
  width: 100% !important;
}

.logo-link{
  /* width: 300px; */
}

.membersImg{
  width: 84%;
  padding-top: 3%;
  padding-right: 0%;
}

#navbarSupportedContent{
  padding-top: 2%;
  margin-left: 16rem!important;
}

.nav-link{
  color: black;
}

.nav-item{
  width: 105px;
  text-align: center;
}

#howItWorks{
  width: 111px;

}

.space-left{
  padding-left: 20%;
}

.navbar-left{
  margin-right: 193px;
}

.button-nav{
  margin:  4.5% 0% 4% 0%;
}

.space-top{
  padding-top: 0.5rem!important;
}

.yellow-active{
  background-color: #f9ee6f;
  color: black !important;
}

.blue-active{
  background-color: #00add9;
  color: white !important;
  border-radius: 10px;
}

.w-100{
  width: 100px !important;
}

a:hover {
  text-decoration: none !important;
}

.nav-item{
  height: 39px;
}

.yellow-underline{
  border-bottom: 3px solid yellow;
  padding-bottom:  0px;
}

.btn-login{
  color: white;
  border-color: white;
  font-size: 1rem;
  border-radius: 8px;
  padding: 2% 10% 3% 10%;
  background-color: #4db6d8;
  text-align: right;
}

.btn-join{
  font-weight: 500;
  background-color: #f6eb52;
  color: black;
  font-size: 1rem;
  border-radius: 8px;
  padding: 2% 10% 3% 10%;
  -webkit-box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important; 
  -moz-box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important; 
  box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important;
}

.btn-yellow{
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border: white 1px solid
  /* -webkit-box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important;
  -moz-box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important;
  box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.15) !important */
}

.thumbnail-provide{
  border-radius: 27px;
  -webkit-box-shadow: -1px 7px 30px -11px rgba(0,0,0,0.39);
  -moz-box-shadow: -1px 7px 30px -11px rgba(0,0,0,0.39);
  box-shadow: -1px 7px 30px -11px rgba(0,0,0,0.39);
}


.member-list-dropdown:hover .dropdown-menu{
  width: 36vw;
}

.member-list-dropdown .dropdown-item {
  font-size: 1rem !important;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.member-list-dropdown p {
  font-size: 11pt;
  font-weight: 400;
}

.p23{
  padding: 23%  !important;
}

.members-dropdown:hover .dropdown-menu{
  width: 70vw;
}

.members-dropdown .dropdown-item {
  font-size: 1rem !important;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.navbar-light .navbar-nav .nav-link{
  color: white;
}

.members-dropdown p {
  font-size: 11pt;
  font-weight: 400;
}

.dropdown:hover .dropdown-menu{
  padding: 4px 0px 4px 0px;
  text-decoration: none;
  font-size: 0.8rem;
  display: block;
}

.supplier-menu:hover .dropdown-menu{
  padding: 12px 0px 20px 29px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-item{
  color: #48afd7;
}

.menu-heading{
  letter-spacing: 6px;
  font-size: 1.2rem !important;
  padding-left: 0;
  font-family: 'Bristone';
}

.dropdown-menu{
  border: 0px;
  border-radius: 6px;
  -webkit-box-shadow: -1px 7px 30px -11px rgba(0,0,0,0.39);
  -moz-box-shadow: -1px 7px 30px -11px rgba(0,0,0,0.39);
  box-shadow: -1px 7px 30px -11px rgba(0,0,0,0.39);
}
